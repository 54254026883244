/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
  Input,
  Label
} from "reactstrap";
import { currencyFormat, currencyFormatWithoutDecimal, currencyFormatWithAlert } from "../../helpers/api_helper";
import DataTable from "react-data-table-component";
//import { useDispatch } from "react-redux";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_MENU_OPTIMIZATION } from "../../helpers/data_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import itemCategory from "../../assets/images/item-category.jpg";
import Loader from "../../Components/Common/Loader";
// import { Basic } from "../MenuEngineering/ScatterCharts";
import { Basic } from "../MenuEngineering/ScatterCharts";
import { BasicModal } from "./ScatterChartsItemName";
//import CustomDatePicker from "../../Components/Common/CustomDatePicker";
import { getDateByName, convertDateFormate } from "../../helpers/api_helper";
import ExportExcel from "../../Components/Common/ExportExcel";
import CustomPagination from "../../Components/Common/CustomPagination";
import ValueAlert from "../../Components/Common/ValueAlert";
import RecommendationFeedback from "./RecommendationFeedback";
import { Line } from "./LineColumnChart";
// import { ViewiconItem } from '../../../src/assets/images/viewItemIcon.png';

/*
const foodItems = [
  {
    name: "Steak and Chips",
    data: [[80, 88]],
  },
  {
    name: "Veal Cutlet",
    data: [[150, 75]],
  },
  {
    name: "Pasta Bolognese",
    data: [[40, 90]],
  },
  {
    name: "Pizza Salami",
    data: [[38, 80]],
  },
  {
    name: "Burger and Chips",
    data: [[120, 80]],
  },
  {
    name: "Pot-au-feu",
    data: [[34, 88]],
  },
];
const beverageItems = {
  "Pot-au-feu": [
    {
      name: "Salade niçoise",
      data: [[80, 35]],
    },
    {
      name: "Anchoiade",
      data: [[85, 87]],
    },
    {
      name: "Bouchée à la reine",
      data: [[45, 95]],
    },
    {
      name: "Creme Brulee",
      data: [[90, 45]],
    },
    {
      name: "Mille-Feuille",
      data: [[60, 88]],
    },
    {
      name: "Chateau Latour (2010)",
      data: [[30, 60]],
    },
    {
      name: "Chateau Lafite Rothschild (2016)",
      data: [[95, 20]],
    },
  ],
};
*/
const itemsPerPage = 50;
const BestSellingProducts = ({
  globalDate,
  rightFilter,
  comparisonDate,
  globalCustomDate,
  activeComparisonData,
  mealPeriod,
  defaultCourse,
  selectedPage,
  setSelectedPage,
  recalculate,
  menuItemData,
}) => {
  const [showModelPopup, setShowModelPopup] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageCount_ServerTable, setPageCount_ServerTable] = useState(0);
  //const [modalcollaboration, setModalCollaboration] = useState(false);
  //const [course, setCourse] = useState("Main Course");
  //const [dishe, setDishe] = useState("Pot-au-feu");

  const [sellingItemAjax, setSellingItemAjax] = useState(true);
  const [sellingItem, setSellingItem] = useState([]);
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const [showVisualModal, setShowVisualModal] = useState(false);
  const [selectedPage_ServerTable, setselectedPage_ServerTable] = useState(0);

  const ShowModelPopupToggle = () => {
    setShowModelPopup(!showModelPopup);
  };
  const [menuItemAjaxModal, setMenuItemAjaxModal] = useState(true);


  /*const togCollaboration = () => {
    setModalCollaboration(!modalcollaboration);
  };*/

  useEffect(() => {
    setSellingItemAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      // postDataAPI({
      //   dateFilter: leftFilterData,
      //   serviceName: POST_MENU_OPTIMIZATION.serviceName,
      //   op: POST_MENU_OPTIMIZATION.selling_item_op,
      //   mealPeriod: mealPeriod,
      //   course: defaultCourse,
      //   page: selectedPage,
      //   recalculate: recalculate,
      // }).then((response) => {
      //   setSellingItemAjax(false);
      //   if (response.status === "ok") {
      //     setSellingItem(response.data.data);
      //     setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
      //   } else {
      //     console.log(response);
      //     setPageCount(0);
      //   }
      // });
      handleSort(SortColumn, 'initial');

    }
  }, [
    globalDate,
    globalCustomDate,
    mealPeriod,
    defaultCourse,
    selectedPage,
    recalculate,
  ]);

  const profitContributionSort = (rowA, rowB) => {
    if (rowA.profit_contribution > rowB.profit_contribution) {
      return 1;
    }
    if (rowB.profit_contribution > rowA.profit_contribution) {
      return -1;
    }
    return 0;
  };
  const popularityContributionSort = (rowA, rowB) => {
    if (rowA.popularity_contribution > rowB.popularity_contribution) {
      return 1;
    }
    if (rowB.popularity_contribution > rowA.popularity_contribution) {
      return -1;
    }
    return 0;
  };
  const performanceGroupSort = (rowA, rowB) => {
    if (rowA.performance_group > rowB.performance_group) {
      return 1;
    }
    if (rowB.performance_group > rowA.performance_group) {
      return -1;
    }
    return 0;
  };
  const columns = [
    {
      id: "name",
      name: <span className="font-weight-bold fs-13">Item</span>,
      selector: (row) => row.name,
      sortable: false,
      width: "250px",
    },
    {
      id: "sold",
      name: <span className="font-weight-bold fs-13">Sold</span>,
      selector: (row) => row.sold,
      sortable: true,
    },
    {
      id: "sell_price",
      name: <span className="font-weight-bold fs-13">Sell Price</span>,
      sortable: true,
      selector: (row) => currencyFormatWithAlert(row.sell_price),
    },
    {
      id: "real_price",
      name: <span className="font-weight-bold fs-13">Real Price</span>,
      sortable: true,
      selector: (row) => currencyFormatWithAlert(row.real_price),
    },
    {
      id: "item_cost",
      name: <span className="font-weight-bold fs-13">Item Cost</span>,
      selector: (row) => currencyFormatWithAlert(row.item_cost),
      sortable: true,
    },
    {
      id: "item_gross_profit",
      name: (
        <span className="font-weight-bold fs-13">
          Item Gross <br />
          Profit
        </span>
      ),
      selector: (row) => currencyFormatWithAlert(row.item_gross_profit),
      sortable: true,
    },
    {
      id: "sales_mix",
      name: <span className="font-weight-bold fs-13">Sales Mix %</span>,
      selector: (row) => row.sales_mix + "%",
      sortable: true,
    },
    {
      id: "total_cost",
      name: <span className="font-weight-bold fs-13">Total Cost</span>,
      selector: (row) => currencyFormatWithAlert(row.total_cost),
      sortable: true,
    },
    {
      id: "total_sales",
      name: <span className="font-weight-bold fs-13">Total Sales</span>,
      sortable: true,
      selector: (row) => currencyFormatWithAlert(row.total_sales),
    },
    {
      id: "contribution_margin",
      name: (
        <span className="font-weight-bold fs-13">
          Contribution <br />
          Margin
        </span>
      ),
      sortable: true,
      selector: (row) => currencyFormatWithAlert(row.ContributionMargin),
    },
    {
      id: "profit_contribution",
      name: (
        <span className="font-weight-bold fs-13">
          Profit <br />
          Contribution
        </span>
      ),
      sortable: true,
      selector: (cell) => {
        switch (cell.profit_contribution) {
          case "Low":
            return (
              <span className="badge badge-soft-danger">
                {" "}
                {cell.profit_contribution}
              </span>
            );
          default:
            return (
              <span className="badge badge-soft-success">
                {" "}
                {cell.profit_contribution}
              </span>
            );
        }
      },
      sortFunction: profitContributionSort,
    },
    {
      id: "popularity_contribution",
      name: (
        <span className="font-weight-bold fs-13">
          Popularity <br />
          Contribution
        </span>
      ),
      sortable: true,
      selector: (cell) => {
        switch (cell.popularity_contribution) {
          case "Low":
            return (
              <span className="badge badge-soft-danger">
                {" "}
                {cell.popularity_contribution}
              </span>
            );
          default:
            return (
              <span className="badge badge-soft-success">
                {" "}
                {cell.popularity_contribution}
              </span>
            );
        }
      },
      sortFunction: popularityContributionSort,
    },
    {
      id: "performance_group",
      name: (
        <span className="font-weight-bold fs-13">
          Performance <br />
          Group
        </span>
      ),
      sortable: true,
      sortFunction: performanceGroupSort,
      selector: (cell) => {
        switch (cell.performance_group) {
          case "Plowhorse":
            return (
              <span onClick={ShowModelPopupToggle} className="badge bg-warning">
                {" "}
                {cell.performance_group}
              </span>
            );
          case "Dog":
            return (
              <span onClick={ShowModelPopupToggle} className="badge bg-danger">
                {" "}
                {cell.performance_group}
              </span>
            );
          case "Cash Cow":
            return (
              <span onClick={ShowModelPopupToggle} className="badge bg-warning">
                {" "}
                {cell.performance_group}
              </span>
            );
          case "Puzzle":
            return (
              <span
                onClick={ShowModelPopupToggle}
                className="badge bg-light text-dark"
              >
                {" "}
                {cell.performance_group}
              </span>
            );
          default:
            return (
              <span onClick={ShowModelPopupToggle} className="badge bg-success">
                {" "}
                {cell.performance_group}
              </span>
            );
        }
      },
    },
  ];
  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField =
        Number(
          selector(rowA)
            .toString()
            .replace(/[^0-9-]+/g, "")
        ) / 100;
      const bField =
        Number(
          selector(rowB)
            .toString()
            .replace(/[^0-9-]+/g, "")
        ) / 100;

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  /*const handlerCourse = (value) => {
    setCourse(value);
  };
  const handlerDishe = (value) => {
    setDishe(value);
  };
  const courseList = [
    {
      value: "Starters",
      label: "Starters",
    },
    {
      value: "Main Course",
      label: "Main Course",
    },
    {
      value: "Sides",
      label: "Sides",
    },
    {
      value: "Desserts",
      label: "Desserts",
    },
  ];
  const allDishesList = {
    Starters: [
      {
        value: "Tempura",
        label: "Tempura",
      },
      {
        value: "Carpacio",
        label: "Carpacio",
      },
      {
        value: "Soup",
        label: "Soup",
      },
      {
        value: "Civeche",
        label: "Civeche",
      },
    ],
    "Main Course": [
      {
        value: "Steak and Chips",
        label: "Steak and Chips",
      },
      {
        value: "Veal Cutlet",
        label: "Veal Cutlet",
      },
      {
        value: "Pasta Bolognese",
        label: "Pasta Bolognese",
      },
      {
        value: "Pizza Salami",
        label: "Pizza Salami",
      },
      {
        value: "Burger and Chips",
        label: "Burger and Chips",
      },
      {
        value: "Pot-au-feu",
        label: "Pot-au-feu",
      },
    ],
    Sides: [
      {
        value: "Skewer",
        label: "Skewer",
      },
      {
        value: "Oyester",
        label: "Oyester",
      },
    ],
    Desserts: [
      {
        value: "Peach Cobbler",
        label: "Peach Cobbler",
      },
      {
        value: "Vanilla Gelato",
        label: "Vanilla Gelato",
      },
    ],
  };
  const dishesList = allDishesList[course];
  const foodItemsSeries = foodItems.filter(function (foodItem) {
    return dishe === "All" ? true : foodItem.name === dishe;
  });
  const filterBeverageItems = beverageItems[dishe] ?? [];
  */

  /******** Sorting **** Starts*****/
  const [SortColumn, setsortColumn] = useState('');

  const [isRotatedname, setIsRotatedname] = useState(false);
  const [isRotatedsold, setIsRotatedsold] = useState(false);
  const [isRotatedsell_price, setIsRotatedsell_price] = useState(false);
  const [isRotatedreal_price, setIsRotatedreal_price] = useState(false);
  const [isRotateditem_cost, setIsRotateditem_cost] = useState(false);
  const [isRotateditem_gross_profit, setIsRotateditem_gross_profit] = useState(false);
  const [isRotatedsales_mix, setIsRotatedsales_mix] = useState(false);
  const [isRotatedtotal_cost, setIsRotatedtotal_cost] = useState(false);
  const [isRotatedtotal_sales, setIsRotatedtotal_sales] = useState(false);
  const [isRotatedContributionMargin, setIsRotatedContributionMargin] = useState(false);
  const [ServersTableArrayAjax, setServersTableArrayAjax] = useState(true);
  const [limitdroddown, setlimitdroddown] = useState('50');

  const [SortType, setSortType] = useState('ASC');
  const handleSort = (value, callfrom) => {

    let sorttypefinalvalue;

    if (callfrom === 'initial') {

      sorttypefinalvalue = localStorage.getItem('Selling_items_SortType');

    } else {

      if (localStorage.getItem('Selling_items_SortType') === '') {
        localStorage.setItem('Selling_items_SortType', 'ASC');
        sorttypefinalvalue = 'ASC';
      } else {
        if (localStorage.getItem('Selling_items_SortType') === 'ASC') {
          localStorage.setItem('Selling_items_SortType', 'DESC');
          sorttypefinalvalue = 'DESC';
        } else {
          localStorage.setItem('Selling_items_SortType', 'ASC');
          sorttypefinalvalue = 'ASC';
        }
      }

    }

    setsortColumn(value);

    if (value === 'name') {
      setIsRotatedname(!isRotatedname);
    }
    if (value === 'sold') {
      setIsRotatedsold(!isRotatedsold);
    }
    if (value === 'sell_price') {
      setIsRotatedsell_price(!isRotatedsell_price);
    }
    if (value === 'real_price') {
      setIsRotatedreal_price(!isRotatedreal_price);
    }
    if (value === 'item_cost') {
      setIsRotateditem_cost(!isRotateditem_cost);
    }
    if (value === 'item_gross_profit') {
      setIsRotateditem_gross_profit(!isRotateditem_gross_profit);
    }
    if (value === 'sales_mix') {
      setIsRotatedsales_mix(!isRotatedsales_mix);
    }
    if (value === 'total_cost') {
      setIsRotatedtotal_cost(!isRotatedtotal_cost);
    }
    if (value === 'total_sales') {
      setIsRotatedtotal_sales(!isRotatedtotal_sales);
    }
    if (value === 'ContributionMargin') {
      setIsRotatedContributionMargin(!isRotatedContributionMargin);
    }

    setSellingItemAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.selling_item_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        sort_column: value,
        sort_type: sorttypefinalvalue,
        limit: limitdroddown
      }).then((response) => {
        setSellingItemAjax(false);
        if (response.status === "ok") {
          setSellingItem(response.data.data);
          setPageCount(Math.ceil(response.data.totalData / limitdroddown));
        } else {
          console.log(response);
          setPageCount(0);
        }
      });
    }

  };
  /************END Sorting**************/

  /*********** Item Name Modal ********/
  const [ServersTableArray, setServersTableArray] = useState([]);
  const [Top_item_Correlation, setTop_item_Correlation] = useState([]);
  const [modalItemName, setModalItemName] = useState(false);
  const [modalItemID, setModalItemID] = useState(false);
  const [CorrelationTableMsg, setCorrelationTableMsg] = useState('');
  function togItemName() {
    setModalItemName(!modalItemName);
  }
  let [ItemNameArray, setItemNameArray] = useState([]);
  const [menuItemDataModal, setMenuItemDataModal] = useState({
    Courses: [],
    MenuItems: [],
  });
  const [item_revenue_chkcount, setitem_revenue_chkcount] = useState([]);

  const handleItemName = (item) => {
    setModalItemID(item.item_id);
    setModalItemName(true);
    for (let i = ItemNameArray.length - 1; i >= 0; i--) {
      ItemNameArray.splice(i, 1);
    }
    ItemNameArray.push(item);

    /****** CALL Item Graph API ******/
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        leftFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.item_graph_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: item && item.item_id, // Check if item is defined
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          setMenuItemDataModal(response.data);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/

    /****** CALL RIght Graph API ******/
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_revenue_chkcount_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: item.item_id,
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          // console.log(response.data.data);
          setitem_revenue_chkcount(response.data.data);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/


    /****** Server Table API Call *******/
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_server_qty_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage_ServerTable,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: item.item_id,
      }).then((response) => {
        // setMenuItemAjaxModal(false);
        setServersTableArrayAjax(false);
        if (response.status === "ok") {
          // console.log(response.data.data);
          setServersTableArray(response.data.data);
          setPageCount_ServerTable(Math.ceil(response.data.totalData / itemsPerPage));
        } else {
          console.log(response);
          setPageCount_ServerTable(0);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/


    /***** Correlation Table API Call *****/
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        rightFilter: rightFilter,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_correlation_op,
        page: selectedPage,
        recalculate: recalculate,
        item_id: item.item_id,
      }).then((response) => {
        setMenuItemAjaxModal(false);
        if (response.status === "ok") {
          // console.log("Top_item_Correlation",response.data.data);
          setTop_item_Correlation(response.data.data.item_data);
          setCorrelationTableMsg(response.data.data.itmCourseMsg);
        } else {
          console.log(response);
        }
      });
    } else {
      console.log(1);
    }
    /***** END *****/

  }
  /*********** END **********/

  useEffect(() => {
    setServersTableArrayAjax(true);
    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_KPI_DASHBOARD.serviceName,
        op: POST_KPI_DASHBOARD.item_server_qty_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage_ServerTable,
        recalculate: recalculate,
        rightFilter: rightFilter,
        item_id: modalItemID,
      }).then((response) => {
        setServersTableArrayAjax(false);
        if (response.status === "ok") {
          // console.log(response.data.data);
          setServersTableArray(response.data.data);
          setPageCount_ServerTable(Math.ceil(response.data.totalData / itemsPerPage));
        } else {
          console.log(response);
          setPageCount_ServerTable(0);
        }
      });

    }
  }, [
    globalDate,
    globalCustomDate,
    mealPeriod,
    defaultCourse,
    selectedPage_ServerTable,
    recalculate,
  ]);

  const handleLimit = (e) => {
    console.log(e.target.value);

    setlimitdroddown(e.target.value);

    if (e.target.value !== '') {

      setSellingItemAjax(true);
      let leftFilterData = false;
      if (
        globalDate.value !== "Custom" ||
        (globalDate.value === "Custom" &&
          globalCustomDate[0] &&
          globalCustomDate[1])
      ) {
        leftFilterData =
          globalDate.value === "Custom"
            ? {
              from: convertDateFormate(globalCustomDate[0]),
              to: convertDateFormate(globalCustomDate[1]),
            }
            : getDateByName(globalDate.value);
      }
      if (leftFilterData) {
        postDataAPI({
          dateFilter: leftFilterData,
          serviceName: POST_MENU_OPTIMIZATION.serviceName,
          op: POST_MENU_OPTIMIZATION.selling_item_op,
          mealPeriod: mealPeriod,
          course: defaultCourse,
          page: 0,
          recalculate: recalculate,
          sort_column: '',
          sort_type: '',
          limit: e.target.value
        }).then((response) => {
          setSellingItemAjax(false);
          if (response.status === "ok") {
            setSellingItem(response.data.data);
            setPageCount(Math.ceil(response.data.totalData / e.target.value));
          } else {
            console.log(response);
            setPageCount(0);
          }
        });
      }
    }

  }

  const handleExport = (e) => {

    let leftFilterData = false;
    if (
      globalDate.value !== "Custom" ||
      (globalDate.value === "Custom" &&
        globalCustomDate[0] &&
        globalCustomDate[1])
    ) {
      leftFilterData =
        globalDate.value === "Custom"
          ? {
            from: convertDateFormate(globalCustomDate[0]),
            to: convertDateFormate(globalCustomDate[1]),
          }
          : getDateByName(globalDate.value);
    }
    if (leftFilterData) {
      postDataAPI({
        dateFilter: leftFilterData,
        serviceName: POST_MENU_OPTIMIZATION.serviceName,
        op: POST_MENU_OPTIMIZATION.selling_item_op,
        mealPeriod: mealPeriod,
        course: defaultCourse,
        page: selectedPage,
        recalculate: recalculate,
        sort_column: '',
        sort_type: '',
        limit: limitdroddown,
        export: 1,
        exportColumns: showColumns
      }).then((response) => {
        setSellingItemAjax(false);
        if (response.status === "ok") {
          // console.log(response.data.data.filename);
          window.open(response.data.data.filename);
        } else {
          console.log(response);

        }
      });
    }

  };

  /******** Show and Hide Columns *********/
  const [sortColumn, SetSortColumn] = useState('');
  const columnDef = [
    {
      name: 'Item',
      key: 'name',
      sort: true,
      className: "text-start",
    },
    {
      name: 'Course',
      key: 'course_name'
    },
    {
      name: 'Sold',
      key: 'sold',
      sort: true,
    },
    {
      name: 'Menu Price',
      key: 'sell_price',
      sort: true,
    },
    {
      name: 'Actualised Price',
      key: 'actualised_price',
    },
    {
      name: 'Item Cost',
      key: 'item_cost',
      sort: true,
    },
    {
      name: 'Item Gross Profit',
      key: 'item_gross_profit'
    },
    {
      name: 'Total Cost',
      key: 'total_cost',
    },
    {
      name: 'Total Sales',
      key: 'total_sales',
      sort: true,
    },
    {
      name: 'Sales Mix',
      key: 'sales_mix',
    },
    {
      name: 'Revenue Mix',
      key: 'revenueMix',
    },
    {
      name: 'Contribution Margin',
      key: 'ContributionMargin',
      sort: true,
    },
    {
      name: 'Profit Contribution',
      key: 'profit_contribution',
    },
    {
      name: 'Popularity Contribution',
      key: 'popularity_contribution',
    },
    {
      name: 'Performance Group',
      key: 'performance_group',
    }
  ]
  const [showColumns, setShowColumns] = useState(columnDef.map((column) => column.key));
  const [isOpen, setIsOpen] = useState(false);
  const columnDropdownRef = createRef();
  const toggleDropdown = (e) => {
    if (columnDropdownRef.current.contains(e.target)) return;

    setIsOpen(!isOpen);
  };
  const handleColumnHideShow = (event, columnName) => {
    event.stopPropagation();
    if (showColumns.includes(columnName)) {
      setShowColumns(showColumns.filter((column) => column != columnName))
    } else {
      setShowColumns([...showColumns, columnName]);
    }
    console.log(event.target.value)
  }
  /****************************************/

  return (
    <>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Selling Items</h4>
          <div className="flex-shrink-0">
            <div className="d-flex">


              <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{ borderRadius: '0.25rem', marginRight: '15px' }}>
                <ButtonGroup>
                  <Button color="info">Columns</Button>
                  <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown-menu-end" end={true}>
                  <div ref={columnDropdownRef}>
                    {columnDef.map((column) => (
                      <DropdownItem onClick={(event) => { handleColumnHideShow(event, column.key) }} key={column.key}>
                        <div className="form-check mb-1">
                          <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key} />
                          <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                        </div>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>
              </Dropdown>


              <span className="p-2">Show</span>
              <select defaultValue="50" className="form-select" id="selling_item_limit" style={{ cursor: 'pointer' }} onChange={handleLimit}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
              <span className="p-2">entries</span>
              {/*<Button
                className="ms-3"
                color="primary"
                onClick={togCollaboration}
              >
                Correlation
              </Button> */}
              <Button
                className="ms-3"
                color="primary"
                onClick={() => setShowVisualModal(true)}
              >
                Visual
              </Button>
              <Button
                className="ms-3"
                color="primary"
                onClick={() => setShowRecommendModal(true)}
              >
                Recommendation
              </Button>
              {/* <ExportExcel fileName="SellingItems" excelData={sellingItem} /> */}
              <Button
                className="ms-3"
                color="primary"
                onClick={handleExport}
              >
                Export
              </Button>



            </div>
          </div>
        </CardHeader>
        <CardBody>
          {sellingItemAjax && <Loader />}
          <div className={sellingItemAjax ? "opacity-0" : ""}>
            {/* <DataTable
              columns={columns}
              data={sellingItem}
              defaultSortFieldId="sold"
              defaultSortAsc={false}
              sortFunction={customSort}
              pagination
            /> */}
            {sellingItemAjax && <Loader />}
            <div
              className={
                sellingItemAjax
                  ? "opacity-0 table-responsive table-card"
                  : "table-responsive table-card"
              }
              style={{ maxHeight: '500px', overflow: 'auto' }}
            >
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                <thead className="text-muted table-light">
                  <tr style={{ position: 'sticky', top: '-2px', zIndex: 100 }}>
                    {columnDef.map((column) => (
                      // eslint-disable-next-line no-undef
                      showColumns.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.name} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn == column.key && sorttypefinalvalue == 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={() => handleSort(column.key, 'sortcall')}></i>}</th>
                    ))}
                    {/* <th scope="col">Item <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedname ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('name','sortcall')}></i></th>
                    <th scope="col">Course</th>
                    <th scope="col" className="text-start">
                      Sold <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedsold ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('sold')}></i>
                    </th>
                    <th scope="col">Menu Price <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedsell_price ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('sell_price','sortcall')}></i></th>
                    <th scope="col">Actualised Price 
                    </th>
                    <th scope="col">Item Cost <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotateditem_cost ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('item_cost','sortcall')}></i></th>
                    <th scope="col">
                      Item Gross <br /> Profit 
                    </th>                    
                    <th scope="col">Total Cost 
                    </th>
                    <th scope="col">Total Sales <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedtotal_sales ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('total_sales','sortcall')}></i></th>
                    <th scope="col">Sales Mix % 
                    </th>
                    <th scope="col">Revenue Mix %</th>
                    <th scope="col">
                      Contribution <br /> Margin <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedContributionMargin ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('ContributionMargin')}></i>
                    </th>
                    <th scope="col">
                      Profit <br /> Contribution
                    </th>
                    <th scope="col">
                      Popularity <br /> Contribution
                    </th>
                    <th scope="col">
                      Performance <br /> Group
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {(sellingItem || []).map((item, key) => (
                    <tr key={key}>

                      {showColumns.includes('name') &&
                        <td className="text-start" onClick={() => handleItemName(item)} style={{ cursor: 'pointer' }}>
                          {item.name}
                          <i className="ri-external-link-line" style={{ fontSize: '18px', marginLeft: '10px', position: 'relative', top: '5px', color: '#405189' }}></i>
                        </td>
                      }
                      {showColumns.includes('course_name') &&
                        <td>
                          <span
                            className={`badge bg-${item.course_name === "Starter"
                              ? "warning"
                              : item.course_name === "Drinks"
                                ? "danger"
                                : item.course_name === "Main"
                                  ? "primary"
                                  : item.course_name === "Desserts"
                                    ? "success"
                                    : item.course_name === "Others"
                                      ? "info"
                                      : "light text-dark"
                              }
                                        `}
                          >
                            {item.course_name}
                          </span>
                        </td>
                      }

                      {showColumns.includes('sold') && <td>{item.sold}</td>}
                      {showColumns.includes('sell_price') && <td>{currencyFormatWithAlert(item.sell_price)}</td>}
                      {showColumns.includes('actualised_price') && <td>{currencyFormatWithAlert(item.actualised_price)}</td>}
                      {showColumns.includes('item_cost') && <td>{currencyFormatWithAlert(item.item_cost)}</td>}
                      {showColumns.includes('item_gross_profit') && <td>{currencyFormatWithAlert(item.item_gross_profit)}</td>}
                      {showColumns.includes('total_cost') && <td>{currencyFormatWithAlert(item.total_cost)}</td>}
                      {showColumns.includes('total_sales') && <td>{currencyFormatWithAlert(item.total_sales)}</td>}
                      {showColumns.includes('sales_mix') && <td>{parseFloat(item.sales_mix).toFixed(2) + "%"}</td>}
                      {showColumns.includes('revenueMix') && <td>{parseFloat(item.revenueMix).toFixed(2) + "%"}</td>}
                      {showColumns.includes('ContributionMargin') && <td>{currencyFormatWithAlert(item.ContributionMargin)}</td>}
                      {showColumns.includes('profit_contribution') &&
                        <td>
                          <span
                            className={`badge badge-soft-${item.profit_contribution === "Low"
                                ? "danger"
                                : "success"
                              }
                            `}
                          >
                            {item.profit_contribution}
                          </span>
                        </td>
                      }
                      {showColumns.includes('popularity_contribution') &&
                        <td>
                          <span
                            className={`badge badge-soft-${item.popularity_contribution === "Low"
                                ? "danger"
                                : "success"
                              }
                            `}
                          >
                            {item.popularity_contribution}
                          </span>
                        </td>
                      }
                      {showColumns.includes('performance_group') &&
                        <td>
                          <span
                            onClick={ShowModelPopupToggle}
                            className={`badge bg-${item.performance_group === "Plowhorse"
                                ? "warning"
                                : item.performance_group === "Dog"
                                  ? "danger"
                                  : item.performance_group === "Cash Cow"
                                    ? "warning"
                                    : item.performance_group === "Puzzle"
                                      ? "light text-dark"
                                      : "success"
                              }
                            `}
                          >
                            {item.performance_group}
                          </span>
                        </td>
                      }

                      {/* <td>{item.sold}</td>
                      <td>{currencyFormatWithAlert(item.sell_price)}</td>
                      <td>{currencyFormatWithAlert(item.actualised_price)}</td>
                      <td>{currencyFormatWithAlert(item.item_cost)}</td>
                      <td>{currencyFormatWithAlert(item.item_gross_profit)}</td>                      
                      <td>{currencyFormatWithAlert(item.total_cost)}</td>
                      <td>{currencyFormatWithAlert(item.total_sales)}</td>
                      <td>{parseFloat(item.sales_mix).toFixed(2)+"%"}</td>
                      <td>{parseFloat(item.revenueMix).toFixed(2)+"%"}</td>
                      <td>{currencyFormatWithAlert(item.ContributionMargin)}</td>
                      <td>
                        <span
                          className={`badge badge-soft-${
                            item.profit_contribution === "Low"
                              ? "danger"
                              : "success"
                          }
                          `}
                        >
                          {item.profit_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-soft-${
                            item.popularity_contribution === "Low"
                              ? "danger"
                              : "success"
                          }
                          `}
                        >
                          {item.popularity_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={ShowModelPopupToggle}
                          className={`badge bg-${
                            item.performance_group === "Plowhorse"
                              ? "warning"
                              : item.performance_group === "Dog"
                              ? "danger"
                              : item.performance_group === "Cash Cow"
                              ? "warning"
                              : item.performance_group === "Puzzle"
                              ? "light text-dark"
                              : "success"
                          }
                          `}
                        >
                          {item.performance_group}
                        </span>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-5 float-end">
              <nav>
                <CustomPagination
                  pageCount={pageCount}
                  setSelectedPage={setSelectedPage}
                />
              </nav>
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={showModelPopup}
        toggle={() => {
          ShowModelPopupToggle();
        }}
        centered
      >
        <ModalHeader className="justify-content-end">
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              ShowModelPopupToggle();
            }}
            aria-label="Close"
          ></button>
        </ModalHeader>
        <ModalBody>
          <img src={itemCategory} className="img-fluid" alt="" />
        </ModalBody>
      </Modal>

      {showRecommendModal && (
        <RecommendationFeedback
          showRecommendModal={showRecommendModal}
          setShowRecommendModal={setShowRecommendModal}
          parentDefaultCourse={defaultCourse}
          parentMealPeriod={mealPeriod}
        />
      )}

      {showVisualModal && (
        <Modal
          isOpen={showVisualModal}
          toggle={() => {
            setShowVisualModal(false);
          }}
          centered
          size="xl"
          backdrop={"static"}
        >
          <ModalHeader className="justify-content-end">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowVisualModal(false);
              }}
              aria-label="Close"
            ></button>
          </ModalHeader>
          <ModalBody>
            <div>
              <Basic
                series={menuItemData.MenuItems}
                leftText="Contribution Margin"
                dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
              />
            </div>
          </ModalBody>
        </Modal>
      )}

      {/*
      <Modal
        className="modal-dialog modal-xl modal-xxl custom-bg"
        isOpen={modalcollaboration}
        toggle={() => {
          togCollaboration();
        }}
        id="modalcollaboration"
      >
        <ModalHeader
          className="modal-title"
          id="modalcollaborationLabel"
          toggle={() => {
            togCollaboration();
          }}
        ></ModalHeader>
        <ModalBody>
          <Row className="mb-3">
            <Col className="mb-2 mb-lg-0" sm={12} lg={6}>
              <select
                className="form-select"
                value={course}
                id="course"
                onChange={(event) => handlerCourse(event.target.value)}
              >
                {courseList.map((time) => {
                  return (
                    <option key={time.value} value={time.value}>
                      {time.label}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className="mb-2 mb-lg-0" sm={12} lg={6}>
              <select
                className="form-select"
                value={dishe}
                id="course"
                placeholder="Select Course"
                onChange={(event) => handlerDishe(event.target.value)}
              >
                <option value="">Select Dish</option>
                {dishesList.map((time) => {
                  return (
                    <option key={time.value} value={time.value}>
                      {time.label}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{dishe}</h4>
                </CardHeader>
                <CardBody>
                  <Basic
                    series={foodItemsSeries}
                    leftText="Contribution Margin"
                    bottomText="Quantity sold"
                    dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]'
                  />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Menu Items</h4>
                </CardHeader>
                <CardBody>
                  <Basic
                    series={filterBeverageItems}
                    bottomText="% Correlation"
                    leftText="Contribution Margin"
                    maxXaxis={100}
                    dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      */}

      {/* Modal for onclick Item Name */}
      <Modal className="modal-dialog modal-fullscreen custom-bg" isOpen={modalItemName} toggle={() => { togItemName(); }} id="fullscreeexampleModal">
        <ModalHeader className="modal-title" id="exampleModalFullscreenLabel" toggle={() => { togItemName(); }}>
          {/* Title here */}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody style={{ overflowY: 'auto' }}>

              {/* Single Item Table */}
              <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                <thead>
                  <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                    <th scope="col">Item </th>
                    <th scope="col">Course </th>
                    <th scope="col" className="text-start">Sold</th>
                    <th scope="col">Menu Price </th>
                    <th scope="col">Actualised Price </th>
                    <th scope="col">Item Cost </th>
                    <th scope="col">Item Gross <br /> Profit</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Total Sales </th>
                    <th scope="col">Sales Mix %</th>
                    <th scope="col">Revenue Mix %</th>
                    <th scope="col">Contribution <br /> Margin</th>
                    <th scope="col">Profit <br /> Contribution</th>
                    <th scope="col">Popularity <br /> Contribution</th>
                    <th scope="col">Performance <br /> Group</th>
                  </tr>
                </thead>
                <tbody>
                  {(ItemNameArray || []).map((item, key) => (
                    <tr key={key} style={{ textAlign: 'center' }}>
                      <td>{item.name}</td>
                      <td>
                        {/* {item.course_name} */}
                        <span
                          onClick={ShowModelPopupToggle}
                          className={`badge bg-${item.course_name === "Starter"
                            ? "warning"
                            : item.course_name === "Drinks"
                              ? "danger"
                              : item.course_name === "Main"
                                ? "primary"
                                : item.course_name === "Desserts"
                                  ? "success"
                                  : item.course_name === "Others"
                                    ? "info"
                                    : "light text-dark"
                            }
                                      `}
                        >
                          {item.course_name}
                        </span>
                      </td>
                      <td>{item.sold}</td>
                      <td>{currencyFormatWithAlert(item.sell_price)}</td>
                      <td>{currencyFormatWithAlert(item.actualised_price)}</td>
                      <td>{currencyFormatWithAlert(item.item_cost)}</td>
                      <td>{currencyFormatWithAlert(item.item_gross_profit)}</td>
                      <td>{currencyFormatWithAlert(item.total_cost)}</td>
                      <td>{currencyFormatWithoutDecimal(item.total_sales)}</td>
                      <td>{item.sales_mix + "%"}</td>
                      <td>{item.revenueMix + "%"}</td>
                      <td>{currencyFormatWithAlert(item.ContributionMargin)}</td>
                      <td>
                        <span
                          className={`badge badge-soft-${item.profit_contribution === "Low"
                              ? "danger"
                              : "success"
                            }
                                `}
                        >
                          {item.profit_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`badge badge-soft-${item.popularity_contribution === "Low"
                              ? "danger"
                              : "success"
                            }
                                `}
                        >
                          {item.popularity_contribution}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={ShowModelPopupToggle}
                          className={`badge bg-${item.performance_group === "Plowhorse"
                              ? "warning"
                              : item.performance_group === "Dog"
                                ? "danger"
                                : item.performance_group === "Cash Cow"
                                  ? "warning"
                                  : item.performance_group === "Puzzle"
                                    ? "light text-dark"
                                    : "success"
                            }
                                `}
                        >
                          {item.performance_group}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Two Charts */}
              <CardBody>
                <Row className="my-3">
                  <Col lg={6}>
                    <BasicModal
                      series={menuItemDataModal.MenuItems}
                      leftText="Contribution Margin"
                      dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info", "--vz-dark", "--vz-secondary", "--vz-warning"]'
                    />
                  </Col>
                  <Col lg={6}>
                    <Line
                      series={item_revenue_chkcount}
                      dataColors='["--vz-primary", "--vz-danger"]'
                    />
                  </Col>
                </Row>
              </CardBody>

              {/*  Title: Top item Correlation Table */}
              <h5>Top Item Correlation</h5>
              <h6> {CorrelationTableMsg !== '' ? '(' + CorrelationTableMsg + ')' : ''} </h6>
              {Top_item_Correlation.length > 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-5">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Category / Tribe</th>
                      <th scope="col">% Chosen</th>
                      <th scope="col">Performance Group</th>
                      <th scope="col">Item Sold</th>
                      <th scope="col">Revenue</th>
                      <th scope="col">Average Check</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Top_item_Correlation.map((item, key) => (
                      <tr key={key} style={{ textAlign: 'center' }}>
                        <td>{item.item_name}</td>
                        <td>
                          <span
                            onClick={ShowModelPopupToggle}
                            className={`badge bg-${item.cfv_display_name === "Starter"
                              ? "warning"
                              : item.cfv_display_name === "Drinks"
                                ? "danger"
                                : item.cfv_display_name === "Main"
                                  ? "primary"
                                  : item.cfv_display_name === "Desserts"
                                    ? "success"
                                    : item.cfv_display_name === "Others"
                                      ? "info"
                                      : "light text-dark"
                              }
                                      `}
                          >
                            {item.cfv_display_name}
                          </span>
                        </td>
                        <td>{item.ref_count} %</td>
                        {/* <td>{item.cat_group !== '' ? item.cat_group : '-'}</td>                                                                        */}
                        <td>
                          <span
                            className={`badge bg-${item.cat_group === "Plowhorse"
                                ? "warning"
                                : item.cat_group === "Dog"
                                  ? "danger"
                                  : item.cat_group === "Cash Cow"
                                    ? "warning"
                                    : item.cat_group === "Puzzle"
                                      ? "light text-dark"
                                      : "success"
                              }
                                      `}
                          >{item.cat_group}</span>
                        </td>
                        <td>{item.item_sold}</td>
                        <td>{currencyFormatWithAlert(item.item_revenue)}</td>
                        <td>{currencyFormatWithAlert(item.avg_check)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              }

              {Top_item_Correlation.length === 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-5">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Category / Tribe</th>
                      <th scope="col">% Chosen</th>
                      <th scope="col">Performance Group</th>
                      <th scope="col">Item Sold</th>
                      <th scope="col">Revenue</th>
                      <th scope="col">Average Check</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={7}>No data</td>
                    </tr>
                  </tbody>
                </Table>
              }

              {/*  Title: Servers Table */}
              <h5>Servers</h5>
              {ServersTableArray.length > 0 &&
                <div className="m-3">
                  <div
                    className={
                      ServersTableArrayAjax
                        ? "opacity-0 table-responsive table-card"
                        : "table-responsive table-card"
                    }
                  >
                    <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                      <thead className="text-muted table-light">
                        <tr>
                          <th scope="col">Name </th>
                          <th scope="col">Quantity sold as %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ServersTableArray.map((item, key) => (
                          <tr key={key} style={{ textAlign: 'center' }}>
                            <td>{item.server_name}</td>
                            <td>{item.qty_sold_perentage} %</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                  <div className="mt-4 float-end">
                    <nav>
                      <CustomPagination
                        pageCount={pageCount_ServerTable}
                        setSelectedPage={setselectedPage_ServerTable}
                      />
                    </nav>
                  </div>
                </div>
                // <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                //     <thead>
                //         <tr style={{textAlign: 'center', backgroundColor: '#f3f3f9'}}>
                //           <th scope="col">Name </th>
                //           <th scope="col">Quantity sold as %</th>                       
                //         </tr>
                //     </thead>
                //     <tbody>
                //         {ServersTableArray.map((item, key) => (
                //           <tr key={key} style={{textAlign: 'center'}}>
                //             <td>{item.server_name}</td>
                //             <td>{item.qty_sold_perentage} %</td>                                                                       
                //         </tr>
                //         ))}
                //     </tbody>
                // </Table>
              }

              {ServersTableArray.length === 0 &&
                <Table className="table-hover table-striped align-middle table-nowrap mb-0">
                  <thead>
                    <tr style={{ textAlign: 'center', backgroundColor: '#f3f3f9' }}>
                      <th scope="col">Name </th>
                      <th scope="col">Quantity sold as %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td colSpan={2}>No data</td>
                    </tr>
                  </tbody>
                </Table>
              }

            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

    </>
  );
};

export default BestSellingProducts;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, createRef, useEffect } from "react";
import { Col, Row, Card, CardHeader, CardBody, Button, Dropdown, DropdownItem, DropdownMenu, ButtonGroup, Input, Label, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import CustomPagination from "../../Components/Common/CustomPagination";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_TABLE_SEAT_OPTIMIZATION } from "../../helpers/data_helper";
import { getDateByName } from "../../helpers/api_helper";
import ExportExcel from "../../Components/Common/ExportExcel";
import { currencyFormatWithAlert } from "../../helpers/api_helper";
import CourseBadge from "../../Components/Common/CourseBadge";

const itemsPerPage = 20;
function RevenueTable({
  activeDate,
  customDate,
  mealPeriod,
  selectedPage,
  setSelectedPage,
}) {
  const [pageCount, setPageCount] = useState(0);
  const [tableRevenueData, setTableRevenueData] = useState([]);
  const [tableRevenueAjax, setTableRevenueAjax] = useState(true);
  useEffect(() => {
    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      var dateFilter =
        activeDate.value === "Custom"
          ? { from: customDate[0], to: customDate[1] }
          : getDateByName(activeDate.value);
      // postDataAPI({
      //   dateFilter: dateFilter,
      //   mealPeriod: mealPeriod,
      //   serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
      //   op: POST_TABLE_SEAT_OPTIMIZATION.table_revenue_op,
      //   page: selectedPage,
      // }).then((response) => {
      //   setTableRevenueAjax(false);
      //   if (response.status === "ok") {
      //     setTableRevenueData(response.data.data);
      //     setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
      //   } else {
      //     console.log(response);
      //     setPageCount(0);
      //   }
      // });

      handleSort(SortColumn, 'initial');
    }
  }, [activeDate, customDate, mealPeriod, selectedPage]);

  /******** Sorting **** Starts*****/
  const [SortColumn, setsortColumn] = useState('');

  const [isRotatedcovers, setIsRotatedcovers] = useState(false);
  const [isRotatedCheckCount, setIsRotatedCheckCount] = useState(false);
  const [isRotatedrevenue, setIsRotatedrevenue] = useState(false);
  const [isRotatedAvgCheck, setIsRotatedAvgCheck] = useState(false);
  const [isRotatedturn_time, setIsRotatedturn_time] = useState(false);
  const [serverModalDetailAjax, setServerModalDetailAjax] = useState(true);
  const [modalServerName, setmodalServerName] = useState('');  
  const [modalServerModal, setModalServerModal] = useState(false);
  const [serverModalArray, setserverModalArray] = useState({});
  function togServerModal() {
    setModalServerModal(!modalServerModal);
  }

  const [SortType, setSortType] = useState('ASC');
  const handleSort = (value, callfrom) => {

    let sorttypefinalvalue;
    if (callfrom === 'initial') {

      sorttypefinalvalue = localStorage.getItem('Table_Seat_optimization_SortType');

    } else {

      if (localStorage.getItem('Table_Seat_optimization_SortType') === '') {
        localStorage.setItem('Table_Seat_optimization_SortType', 'ASC');
        sorttypefinalvalue = 'ASC';
      } else {
        if (localStorage.getItem('Table_Seat_optimization_SortType') === 'ASC') {
          localStorage.setItem('Table_Seat_optimization_SortType', 'DESC');
          sorttypefinalvalue = 'DESC';
        } else {
          localStorage.setItem('Table_Seat_optimization_SortType', 'ASC');
          sorttypefinalvalue = 'ASC';
        }
      }

    }

    setsortColumn(value);

    if (value === 'covers') {
      setIsRotatedcovers(!isRotatedcovers);
    }
    if (value === 'CheckCount') {
      setIsRotatedCheckCount(!isRotatedCheckCount);
    }
    if (value === 'revenue') {
      setIsRotatedrevenue(!isRotatedrevenue);
    }
    if (value === 'AvgCheck') {
      setIsRotatedAvgCheck(!isRotatedAvgCheck);
    }
    if (value === 'turn_time') {
      setIsRotatedturn_time(!isRotatedturn_time);
    }


    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;

    let dateval = activeDate.value === "Custom" ? { from: datefrom, to: dateto } : getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      // var dateFilter = 
      //   activeDate.value === "Custom"
      //     ? { from: customDate[0], to: customDate[1] }
      //     : getDateByName(activeDate.value);
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_revenue_op,
        page: selectedPage,
        sort_column: value,
        sort_type: sorttypefinalvalue,
      }).then((response) => {
        setTableRevenueAjax(false);
        if (response.status === "ok") {
          setTableRevenueData(response.data.data);
          setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
        } else {
          console.log(response);
          setPageCount(0);
        }
      });
    }

  };
  /************END Sorting**************/

  /********** Handle Export ********/
  const handleExport = (e) => {

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;

    let dateval = activeDate.value === "Custom" ? { from: datefrom, to: dateto } : getDateByName(activeDate.value);

    if (
      activeDate.value !== "Custom" ||
      (activeDate.value === "Custom" && customDate[0] && customDate[1])
    ) {
      // var dateFilter = 
      //   activeDate.value === "Custom"
      //     ? { from: customDate[0], to: customDate[1] }
      //     : getDateByName(activeDate.value);
      postDataAPI({
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: POST_TABLE_SEAT_OPTIMIZATION.serviceName,
        op: POST_TABLE_SEAT_OPTIMIZATION.table_revenue_op,
        page: selectedPage,
        sort_column: '',
        sort_type: '',
        export: 1,
        exportColumns: showColumns
      }).then((response) => {
        setTableRevenueAjax(false);
        if (response.status === "ok") {
          window.open(response.data.data.filename);
        } else {
          console.log(response);
        }
      });
    }

  };

  /******** Show and Hide Columns *********/
  const [sortColumn, SetSortColumn] = useState('');
  const columnDef = [
    {
      name: 'Covers',
      key: 'covers',
      sort: true,
      className: "text-start",
    },
    {
      name: 'CheckCount',
      key: 'CheckCount'
    },
    {
      name: 'Revenue',
      key: 'revenue',
      sort: true,
    },
    {
      name: 'AvgCheck',
      key: 'AvgCheck'
    },
    {
      name: 'Turn time Minute(s)',
      key: 'turn_time'
    },
    {
      name: 'Shortest Check Time Minute(s)',
      key: 'shortest_check_time'
    },
    {
      name: 'Longest Check Time Minute(s)',
      key: 'longest_check_time'
    }
  ]
  const [showColumns, setShowColumns] = useState(columnDef.map((column) => column.key));
  const [isOpen, setIsOpen] = useState(false);
  const columnDropdownRef = createRef();
  const toggleDropdown = (e) => {
    if (columnDropdownRef.current.contains(e.target)) return;

    setIsOpen(!isOpen);
  };
  const handleColumnHideShow = (event, columnName) => {
    event.stopPropagation();
    if (showColumns.includes(columnName)) {
      setShowColumns(showColumns.filter((column) => column != columnName))
    } else {
      setShowColumns([...showColumns, columnName]);
    }
    console.log(event.target.value)
  }
  /****************************************/

  // Define the style object
  const spanStyle = {
    background: '#fff',
    color: '#405189',
    padding: '2px 8px',
    borderRadius: '6px',
    marginLeft: '5px'
  };

  const modalbodystyle = {
    backgroundColor: '#405189',
    color: '#fff',
    borderRadius: '8px 8px 0px 0px'
  }

  const handleDataPointSelection = (item, label) => {
    
    let sl_time;

    if(label === "shortest_check_time"){
      setmodalServerName("Shortest Check Time Minute(s)");
      sl_time = item.shortest_check_time;
    }else{
      setmodalServerName("Longest Check Time Minute(s)");
      sl_time = item.longest_check_time;
    }        

    if (activeDate.value !== 'Custom' || (activeDate.value === 'Custom' && customDate[0] && customDate[1])) {
      setModalServerModal(true);
      setServerModalDetailAjax(true);

      var originalDate = new Date(customDate[0]);  
      var year = originalDate.getFullYear();
      var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
      var day = originalDate.getDate().toString().padStart(2, '0');
      var datefrom = year + '-' + month + '-' + day;
      var originalDate2 = new Date(customDate[1]);    
      var year2 = originalDate2.getFullYear();
      var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
      var day2 = originalDate2.getDate().toString().padStart(2, '0');    
      var dateto = year2 + '-' + month2 + '-' + day2;
      let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

      var postdata = {
        dateFilter: dateval,
        mealPeriod: mealPeriod,
        serviceName: 'table_seat_data',
        op: 'cover_sl_check_details',          
        covers: item.covers,
        sl_time: sl_time,
        clientName: "localhost"
      };
      console.log(postdata);
      
      postDataAPI(postdata).then((response) => {
          setServerModalDetailAjax(false);
          if (response.status === "ok") {
              setserverModalArray(response.data.data);
          } else {
              setModalServerModal(false);    
          }
      });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Table Revenue</h4>
          <div className="flex-shrink-0">
            <div className="d-flex">
              {/* <ExportExcel fileName="TableRevenue" excelData={tableRevenueData} /> */}

              <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-3" style={{ borderRadius: '0.25rem' }}>
                <ButtonGroup>
                  <Button color="info">Columns</Button>
                  <DropdownToggle tag="button" className="btn btn-info" split></DropdownToggle>
                </ButtonGroup>
                <DropdownMenu className="dropdown-menu-end" end={true}>
                  <div ref={columnDropdownRef}>
                    {columnDef.map((column) => (
                      <DropdownItem onClick={(event) => { handleColumnHideShow(event, column.key) }} key={column.key}>
                        <div className="form-check mb-1">
                          <Input className="form-check-input" type="checkbox" id={`column${column.key}`} checked={showColumns.includes(column.key)} value={column.key} />
                          <Label className="form-check-label" for="formCheck6">{column.name}</Label>
                        </div>
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>
              </Dropdown>

              <Button
                className="ms-3"
                color="primary"
                onClick={handleExport}
              >
                Export
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          {tableRevenueAjax && <Loader />}
          <div
            className={
              tableRevenueAjax
                ? "opacity-0 table-responsive table-card"
                : "table-responsive table-card"
            }
            style={{ maxHeight: '500px', overflow: 'auto' }}
          >
            <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
              <thead className="text-muted table-light">
                <tr style={{ position: 'sticky', top: '-2px', zIndex: 100 }}>
                  {columnDef.map((column) => (
                    // eslint-disable-next-line no-undef
                    showColumns.includes(column.key) && <th className={column.className} key={column.key} scope="col">{column.name} {column.sort && <i className="las la-arrow-down" style={{ cursor: 'pointer', transform: (sortColumn == column.key && sorttypefinalvalue == 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)') }} onClick={() => handleSort(column.key, 'sortcall')}></i>}</th>
                  ))}
                  {/* <th scope="col">Covers <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedcovers ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('covers','sortcall')}></i> </th>
                  <th scope="col">CheckCount <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedCheckCount ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('CheckCount','sortcall')}></i></th>
                  <th scope="col">Revenue <i className="las la-arrow-down" style={{cursor: 'pointer', transform: isRotatedrevenue ? 'rotate(180deg)' : 'rotate(0deg)'}} onClick={() => handleSort('revenue','sortcall')}></i></th>
                  <th scope="col">AvgCheck 
                  </th>
                  <th scope="col">Turn time Minute(s) 
                  </th>
                  <th scope="col">Shortest Check Time Minute(s) </th>
                  <th scope="col">Longest Check Time Minute(s) </th> */}
                </tr>
              </thead>
              <tbody>
                {(tableRevenueData || []).map((item, key) => (
                  <tr key={key}>
                    {showColumns.includes('covers') && <td className="text-start">{(item.covers < 0) ? <span className={item.covers < 0 ? "text-danger" : ""}>({item.covers})</span> : item.covers}</td>}
                    {showColumns.includes('CheckCount') && <td>{(item.CheckCount < 0) ? <span className={item.CheckCount < 0 ? "text-danger" : ""}>({item.CheckCount})</span> : item.CheckCount}</td>}
                    {showColumns.includes('revenue') && <td>{currencyFormatWithAlert(item.revenue)}</td>}
                    {showColumns.includes('AvgCheck') && <td>{currencyFormatWithAlert(item.AvgCheck)}</td>}
                    {showColumns.includes('turn_time') && <td>{(item.turn_time < 0) ? <span className={"text-danger"}>({Math.abs(item.turn_time)})</span> : item.turn_time}</td>}
                    {/* {showColumns.includes('shortest_check_time') && <td>{(item.shortest_check_time < 0) ? <span className={"text-danger"}>({Math.abs(item.shortest_check_time)})</span> : item.shortest_check_time}</td> } */}
                    {showColumns.includes('shortest_check_time') && (
                      <td>
                        {(item.shortest_check_time < 0) ? (
                          <span className={"text-danger"} onClick={() => handleDataPointSelection(item, 'shortest_check_time')} style={{ cursor: 'pointer', color: '#405189' }}>
                            ({Math.abs(item.shortest_check_time)})
                          </span>
                        ) : (
                          <span onClick={() => handleDataPointSelection(item, 'shortest_check_time')} style={{ cursor: 'pointer', color: '#405189' }}>
                            {item.shortest_check_time}
                          </span>
                        )}
                      </td>
                    )}
                    {showColumns.includes('longest_check_time') && (
                      <td>
                        {(item.longest_check_time < 0) ? (
                          <span className={"text-danger"} onClick={() => handleDataPointSelection(item, 'longest_check_time')} style={{ cursor: 'pointer', color: '#405189' }}>
                            ({Math.abs(item.longest_check_time)})
                          </span>
                        ) : (
                          <span onClick={() => handleDataPointSelection(item, 'longest_check_time')} style={{ cursor: 'pointer', color: '#405189' }}>
                            {item.longest_check_time}
                          </span>
                        )}
                      </td>
                    )}
                    {/* {showColumns.includes('longest_check_time') && <td>{(item.longest_check_time < 0) ? <span className={"text-danger"}>({Math.abs(item.longest_check_time)})</span> : item.longest_check_time}</td> } */}

                    {/* <td>
                      {(item.covers < 0) ? <span className={item.covers<0?"text-danger":""}>({item.covers})</span> : item.covers}
                    </td> *
                    <td>
                      {(item.CheckCount < 0) ? <span className={item.CheckCount<0?"text-danger":""}>({item.CheckCount})</span> : item.CheckCount}
                    </td>
                    <td>{currencyFormatWithAlert(item.revenue)}</td>
                    <td>{currencyFormatWithAlert(item.AvgCheck)}</td>
                    <td>
                      {(item.turn_time < 0) ? <span className={"text-danger"}>({Math.abs(item.turn_time)})</span> : item.turn_time}
                    </td>
                      <td>{(item.shortest_check_time < 0) ? <span className={"text-danger"}>({Math.abs(item.shortest_check_time)})</span> : item.shortest_check_time}
                    </td>
                    <td>
                    {(item.longest_check_time < 0) ? <span className={"text-danger"}>({Math.abs(item.longest_check_time)})</span> : item.longest_check_time}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 float-end">
            <nav>
              <CustomPagination
                pageCount={pageCount}
                setSelectedPage={setSelectedPage}
              />
            </nav>
          </div>
        </CardBody>
      </Card>
      <Modal className="modal-dialog modal-lg custom-bg" isOpen={modalServerModal} toggle={() => { togServerModal(); }} id="fullscreeexampleModal">
        <ModalHeader className="modal-title" id="exampleModalFullscreenLabel" toggle={() => { togServerModal(); }}>
          <h5>{modalServerName}</h5>
        </ModalHeader>
        <ModalBody>
          <Card className="mb-1">
            <CardBody style={modalbodystyle}>

                {!serverModalDetailAjax && <>
                  
                  <div style={{display: 'flex', textAlign: 'center'}}>
                    <div>
                      Cover :
                      <span style={spanStyle}>
                        {serverModalArray.checkInfo.covers}
                      </span>
                    </div>
                    <div style={{marginLeft: '15px'}}>
                      Avg Check :
                      <span style={spanStyle}>
                        {currencyFormatWithAlert(serverModalArray.checkInfo.avgCheck)}
                      </span>
                    </div>
                    <div style={{marginLeft: '15px'}}>
                      Revenue :
                      <span style={spanStyle}>
                        {currencyFormatWithAlert(serverModalArray.checkInfo.revenue)}
                      </span>
                    </div>
                  </div>
                  
                  <div style={{display: 'flex', textAlign: 'center'}} className="mt-3">
                    <div>
                      Opening Time :
                      <span style={spanStyle}>
                        {serverModalArray.checkInfo.start_time}
                      </span>
                    </div>
                    <div style={{marginLeft: '15px'}}>                      
                      Closing Time :
                      <span style={spanStyle}>
                        {serverModalArray.checkInfo.end_time}
                      </span>
                    </div>
                  </div>
                  </>
                }                            
            </CardBody>
          </Card>

          <Card style={{ borderRadius: '0px 0px 8px 8px' }}>
            <CardBody>
              {
                serverModalDetailAjax && <Loader />
              }
              {!serverModalDetailAjax && <>
                <div
                  className={
                    serverModalDetailAjax
                      ? "opacity-0 table-responsive table-card"
                      : "table-responsive table-card"
                  }
                  style={{maxHeight: '450px',overflow: 'auto'}}
                >
                  <table className="table table-borderless table-centered align-middle table-nowrap mb-3 text-center">
                    <thead className="text-muted table-light">
                      <tr style={{position: 'sticky',top: '-2px', zIndex: 100}}>
                        <th scope="col">Item Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                          {(serverModalArray.itemInfo || []).map((item, key) => (
                              <tr key={key}>                                  
                                  <td>{item.item_name}</td>
                                  <td><CourseBadge course_name={item.category}></CourseBadge></td>
                                  <td>{item.qty}</td>
                                  <td>{currencyFormatWithAlert(item.item_revenue)}</td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
                </div>
              </>
              }
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      
    </React.Fragment>
  );
}

export { RevenueTable };

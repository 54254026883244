import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

const BreadCrumb = ({ title, pageTitle, handleRefresh }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="mb-sm-0 pe-3">{title}</h4>              
                {/* <button
                    onClick={handleRefresh}
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >                                       
                    <i className='bx bx-refresh fs-2'></i>
                </button> */}
            </div>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import classnames from "classnames";
import { Container,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    Col,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane } from "reactstrap";
import Loader from "../../Components/Common/Loader"
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { postDataAPI } from "../../helpers/phpbackend_helper";
import { POST_KPI_DASHBOARD } from "../../helpers/data_helper";
import {getDateByName, currencyFormatWithAlert, convertDateFormate, courseColor} from "../../helpers/api_helper";
import CustomPagination from "../../Components/Common/CustomPagination";
import ValueAlert from "../../Components/Common/ValueAlert"
import CourseBadge from "../../Components/Common/CourseBadge";
import PerformanceBadge from "../../Components/Common/PerformanceBadge";
var chartStackedBarColors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-secondary", "--vz-danger", "--vz-info"]');
const itemsPerPage = 50;

const ServerModal = ({activeDate, customDate, mealPeriod, server_id, selectedPage, showDetailsModal, setShowDetailsModal, selectedPageST, setSelectedPageST}) => {
    const [serverData, setServerData] = useState([]);
    const [serverPerformanceAjax, setServerPerformanceAjax] = useState(true);
    const [serverPerformanceDetailAjax, setServerPerformanceDetailAjax] = useState(true);
    const [serverModalArray, setserverModalArray] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isRotatedcovers, setIsRotatedcovers] = useState(false);
    const [sorttypefinalvalue, setsorttypefinalvalue] = useState('');
    const [SortColumn, setsortColumn] = useState('');

    const [justifyTab, setjustifyTab] = useState("course");
    const justifyToggle = (tab) => {
        if (justifyTab !== tab) {
            setjustifyTab(tab);
        }
    };

    // Create a Date object from the original date string
    var originalDate = new Date(customDate[0]);

    // Extract the year, month, and day from the Date object
    var year = originalDate.getFullYear();
    var month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day = originalDate.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var datefrom = year + '-' + month + '-' + day;

    // Create a Date object from the original date string
    var originalDate2 = new Date(customDate[1]);

    // Extract the year, month, and day from the Date object
    var year2 = originalDate2.getFullYear();
    var month2 = (originalDate2.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    var day2 = originalDate2.getDate().toString().padStart(2, '0');

    // Create the formatted date string
    var dateto = year2 + '-' + month2 + '-' + day2;
        
    let dateval = activeDate.value === "Custom"? { from: datefrom, to: dateto }: getDateByName(activeDate.value);

    const serverSeries = serverData.serverSeries;
    const serverOptionData = [];

    const [modalServerModal, setModalServerModal] = useState(false);
    function togServerModal() {        
        setModalServerModal(!modalServerModal);
    }

    for (const key in serverData.serverOptions) {
        serverOptionData.push(serverData.serverOptions[key]['name']);
    }

    // Event handler for dataPointSelection
    const [modalServerName, setmodalServerName] = useState('');
    //const [modalCourseName, setmodalCourseName] = useState('');
    const [modalServerID, setmodalServerID] = useState('');
    //const [modalCourseID, setmodalCourseID] = useState('');
    const [openFlag, setopenFlag] = useState('');
    const [modalServerCheckAvg,setModalServerCheckAvg]=useState(0);
    const handleDataPointSelection = (event, chartContext, config) => {                
        
        let post_server_id;
        let post_course_id;
        const { seriesIndex, dataPointIndex, w } = config;
        // console.log(w.config.series[seriesIndex]['name'], ':', w.config.series[seriesIndex].data[dataPointIndex]);   
            
        const avgCheckData=serverSeries[serverSeries.length - 1].data;
        post_server_id = serverData.serverOptions[dataPointIndex]['server_id'];
        setmodalServerName(serverData.serverOptions[dataPointIndex]['name']);
        setmodalServerID(post_server_id);
        setModalServerCheckAvg(avgCheckData[dataPointIndex]);

        //if(w.config.series[seriesIndex]['name'] !== 'AvgCheck'){            
            
            if(activeDate.value!=='Custom' || (activeDate.value==='Custom' && customDate[0] && customDate[1])){                                
                setServerPerformanceDetailAjax(true);
                setModalServerModal(true);
                postDataAPI({
                    dateFilter: activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
                    serviceName: 'server_data',
                    op: 'server_course_items',
                    mealPeriod:mealPeriod,
                    server_id: post_server_id,
                    course_id: post_course_id, 
                    page: selectedPageST,
                }).then((response) => {
                    setServerPerformanceDetailAjax(false);
                    if (response.status === "ok") {
                        setserverModalArray(response.data.data);
                        setopenFlag(true);
                        setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
                    } else {
                        // console.log(response);
                        setModalServerModal(false); 
                        setPageCount(0);
                        setopenFlag(false);
                    }
                });
            }
        //}
    };

    useEffect(() => {

        if(openFlag === true){
            if(activeDate.value!=='Custom' || (activeDate.value==='Custom' && customDate[0] && customDate[1])){                                
                setServerPerformanceDetailAjax(true);
                // setModalServerModal(true);
                postDataAPI({
                    dateFilter: activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
                    serviceName: 'server_data',
                    op: 'server_course_items',
                    mealPeriod:mealPeriod,
                    server_id: modalServerID,
                    //course_id: modalCourseID, 
                    page: selectedPageST,
                    // recalculate: recalculate,
                    // sort_column: SortColumn,
                    // sort_type: sorttypefinalvalue,
                }).then((response) => {
                    setServerPerformanceDetailAjax(false);
                    if (response.status === "ok") {
                        setserverModalArray(response.data.data);
                        setPageCount(Math.ceil(response.data.totalData / itemsPerPage));
                    } else {
                        // console.log(response);
                        setModalServerModal(false); 
                        setPageCount(0);
                    }
                });
            }
        }        

    },[activeDate,customDate, mealPeriod,server_id, selectedPageST])

    const courseColorList=[];
    if(serverSeries){
        Object.values(serverSeries).forEach((ss)=>{
            if(courseColor[ss.name]){
                courseColorList.push(courseColor[ss.name]);
            }
        })
    }

    var serverOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
            },
            events: {
                dataPointSelection: handleDataPointSelection,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        title: {
            text: '',
        },
        xaxis: {
            categories: serverOptionData,
            labels: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: courseColorList,
    };

    useEffect(() => {

        

        if(activeDate.value!=='Custom' || (activeDate.value==='Custom' && customDate[0] && customDate[1])){            
            setServerPerformanceAjax(true);
            postDataAPI({
                dateFilter: activeDate.value==='Custom'?{from:convertDateFormate(customDate[0]),to:convertDateFormate(customDate[1])}:getDateByName(activeDate.value),
                serviceName: POST_KPI_DASHBOARD.serviceName,
                op: POST_KPI_DASHBOARD.server_course_performance_op,
                mealPeriod:mealPeriod,
                server_id:server_id,
                page: selectedPage,
            }).then((response) => {
                setServerPerformanceAjax(false);
                if (response.status === "ok") {
                    setServerData(response.data);
                } else {
                console.log(response);
                }
            });
        }
    },[activeDate,customDate, mealPeriod,server_id, selectedPage])
    let totalRec=serverData.serverOptions && serverData.serverOptions.length>0?serverData.serverOptions.length:1;


    const handleSort = (value) => {

        // let sorttypefinalvalue;

        if(localStorage.getItem('Singleitem_SortType') === ''){
            localStorage.setItem('Singleitem_SortType', 'ASC');
            setsorttypefinalvalue('ASC');                
        }else{
          if(localStorage.getItem('Singleitem_SortType') === 'ASC'){   
            localStorage.setItem('Singleitem_SortType', 'DESC');
            setsorttypefinalvalue('DESC');
          }else{
            localStorage.setItem('Singleitem_SortType', 'ASC');
            setsorttypefinalvalue('ASC');
          }
        }

        setsortColumn(value);

        if(value === 'qty'){
            setIsRotatedcovers(!isRotatedcovers);  
        }

        handleDataPointSelection();

    }

    return (
        <Modal
        size="xl"
        isOpen={showDetailsModal}
        toggle={() => {
          setShowDetailsModal(false);
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader
          toggle={() => {
            setShowDetailsModal(false);
          }}
          className="mb-2"
        >
          Server 
        </ModalHeader>
        <ModalBody>
          <Container>
                {
                    serverPerformanceAjax && <Loader />
                }
                { !serverPerformanceAjax && serverSeries &&
                <ReactApexChart
                    options={serverOptions}
                    series={serverSeries}
                    type="bar"
                    height={500}
                />
                }

                {/* Modal for onclick Server Chart */}
                <Modal className="modal-dialog modal-lg custom-bg" isOpen={modalServerModal} toggle={() => { togServerModal(); }} id="fullscreeexampleModal">
                    <ModalHeader className="modal-title" id="exampleModalFullscreenLabel" toggle={() => {togServerModal();}}>
                        {/* My Model */}
                    </ModalHeader>
                    <ModalBody>
                    <Card className="mb-1">                                             

                        <CardBody style={{backgroundColor: '#405189', color: '#fff', borderRadius: '8px 8px 0px 0px'}}>
                            <Row>
                                <Col lg={4}>
                                    Server Name : 2222
                                    <span style={{background: '#fff', color:'#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px'}}>
                                        {modalServerName}
                                    </span>
                                </Col>
                                {!serverPerformanceDetailAjax && <>
                                <Col lg={2}>
                                    Cover : 
                                    <span style={{background: '#fff', color:'#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px'}}>
                                        {serverModalArray.cover_percentage}%
                                    </span>
                                </Col>
                                <Col lg={3}>
                                    Revenue : 
                                    <span style={{background: '#fff', color:'#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px'}}>
                                        {serverModalArray.revenue_percetage}%
                                    </span>
                                </Col>
                                <Col lg={3}>
                                    Avg Check : 
                                    <span style={{background: '#fff', color:'#405189', padding: '2px 8px', borderRadius: '6px', marginLeft: '5px'}}>
                                    {currencyFormatWithAlert(modalServerCheckAvg)}
                                    </span>                                        
                                </Col>
                                </>}
                            </Row>
                        </CardBody>
                    </Card>

                    <Nav pills className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                        <NavItem>
                            <NavLink style={{ cursor: "pointer", background:"transparent" }} className={classnames({ active: justifyTab === "course", })} onClick={() => { justifyToggle("course"); }} >
                                Course
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyTab === "item", })} onClick={() => { justifyToggle("item"); }} >
                                Item
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={justifyTab} className="text-muted">

                        <TabPane tabId="course" id="course">
                            <Card style={{borderRadius: '0px 0px 8px 8px'}}>                                                                                       
                                <CardBody>                                                        
                                {
                                    serverPerformanceDetailAjax && <Loader />
                                }
                                {!serverPerformanceDetailAjax &&   <>            
                                    <div
                                        className={
                                            serverPerformanceDetailAjax
                                            ? "opacity-0 table-responsive table-card"
                                            : "table-responsive table-card"
                                        }
                                        style={{maxHeight: '500px', overflow: 'auto'}}
                                    >
                                        <table className="table table-borderless table-centered align-middle table-nowrap mb-3 text-center">
                                            <thead className="text-muted table-light">
                                                <tr style={{position: 'sticky',top: '-2px', zIndex: 100}}>
                                                    <th scope="col">Course</th>
                                                    <th scope="col">Sold</th>
                                                    <th scope="col">Avg Check</th>
                                                    <th scope="col">Vs Team Check</th>
                                                    <th scope="col">Course Rev of Overall (%)</th>
                                                    <th scope="col">Performance</th>
                                                
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { (serverModalArray.courses || []).map((course, key) => (
                                                    <tr key={key}>
                                                        <td><CourseBadge course_name={course.course_name} /></td>
                                                        <td>{course.sold}</td>
                                                        <td>{currencyFormatWithAlert(course.average_check)}</td>
                                                        <td>{currencyFormatWithAlert(course.team_check)}</td>
                                                        <td>{(course.revenue_percetage < 0) ? <span className={course.revenue_percetage<0?"text-danger":""}>({-course.revenue_percetage}%)</span> : <span className="">{course.revenue_percetage}%</span>}</td>
                                                        <td><PerformanceBadge title={course.performance} /></td>
                                                        
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                }
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="item" id="item">
                            <Card style={{borderRadius: '0px 0px 8px 8px'}}>                                                                                       
                                <CardBody>
                                {
                                    serverPerformanceDetailAjax && <Loader />
                                }
                                {!serverPerformanceDetailAjax &&   <> 
                                    <div
                                className={
                                    serverPerformanceDetailAjax
                                    ? "opacity-0 table-responsive table-card"
                                    : "table-responsive table-card"
                                }
                                style={{maxHeight: '500px', overflow: 'auto'}}
                            >
                                <table className="table table-borderless table-centered align-middle table-nowrap mb-0 text-center">
                                    <thead className="text-muted table-light">
                                        <tr style={{position: 'sticky',top: '-2px', zIndex: 100}}>
                                            <th scope="col">Name</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">% of Total Sold</th>
                                            <th scope="col">Performance Group</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { (serverModalArray.items || []).map((item, key) => (
                                            <tr key={key}>
                                                <td>{item.item_name}</td>
                                                <td><CourseBadge course_name={item.category} /></td>
                                                <td>{item.chosen_percetage}%</td>
                                                <td>
                                                    <span
                                                    className={`badge bg-${
                                                        item.performance_group === "Plowhorse"
                                                        ? "warning"
                                                        : item.performance_group === "Dog"
                                                        ? "danger"
                                                        : item.performance_group === "Cash Cow"
                                                        ? "warning"
                                                        : item.performance_group === "Puzzle"
                                                        ? "light text-dark"
                                                        : "success"
                                                    }
                                                    `}
                                                    >
                                                    {item.performance_group}
                                                    </span>
                                                </td>
                                            </tr>
                                        )) }
                                    </tbody>
                                </table>                                 

                            </div>
                                </>
                                } 
                                </CardBody>
                            </Card>                  
                        </TabPane>
                    </TabContent>
                                       
                    </ModalBody>
                </Modal>

          </Container>
        </ModalBody>
      </Modal>
    )
}
export default ServerModal;